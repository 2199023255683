
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  computed,
  onUpdated,
} from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "@/views/merchant-order/order-request/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiOrderRequest } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import store from "@/store";
import {
  getMerchantOrderProgress,
  getMerchantOrderRouterPath,
  getOrderRequestProgressClass,
  getOrderRequestTypeOptions,
} from "@/core/directive/function/orderRequest";
import { OrderRequestTable } from "@/core/directive/interface/orderRequest";
import OrderRequestV2Item from "@/components/table/OrderRequestV2Item.vue";
import { OrderRequestType } from "@/core/directive/type/orderRequest";
import {
  CommonArrayToString,
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  getDiffDays,
  getLateDays,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import ImportModal from "@/views/merchant-order/order-request/ImportModal.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import { isMB } from "@/core/directive/function/user";

export default defineComponent({
  name: "merchant-order-order-processing",
  components: {
    MBDatatablePlus,
    FilterDropdown,
    OrderRequestV2Item,
    ImportModal,
    PermissionCommon,
    WindowResize,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const disabledExportConfirmedTrack = ref(false);
    const disabledExportIncomingShipments = ref(false);
    const tableData = ref<Array<OrderRequestTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");

    const options = ref({
      type: "",
      typeOptions: getOrderRequestTypeOptions(t),
      uploadUrl: ApiOrderRequest.importConfirmedTrack(),
      isDefault: false,
    });

    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "order_progress",
        value: getMerchantOrderProgress(route),
        condition: "in",
      },
    ]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "request_number", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("orderRequest.orderNo"),
        key: "request_number",
        sortable: true,
      },
      {
        name: t("orderRequest.requestDate"),
        key: "request_date",
        sortable: false,
      },
      {
        name: t("orderRequest.merchantVendor"),
        key: "merchant_vendor",
        sortable: false,
      },
      {
        name: t("orderRequest.company"),
        key: "company",
        sortable: false,
      },
      {
        name: t("orderRequest.representative"),
        key: "representative",
        sortable: false,
      },
      {
        name: t("orderRequest.qty"),
        key: "qty",
        sortable: false,
        align: "right",
      },
      {
        name: t("orderRequest.total"),
        key: "total",
        sortable: false,
        align: "right",
      },
      {
        name: t("orderRequest.status"),
        key: "status",
        sortable: false,
        align: "right",
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      setModuleBCN(t, route);
      changeDefaultType();
    };

    const changeDefaultType = () => {
      if (!options.value.type) {
        if (isMB.value) {
          options.value.type = String(OrderRequestType.DoubleSaleOrder);
        } else {
          options.value.type =
            String(OrderRequestType.MerchantOrder) +
            "," +
            String(OrderRequestType.Draft);
        }
      }
      getOrderRequestList();
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    const getCommonFilterOptions = computed(() => {
      return [
        {
          field: "type",
          value: options.value.type.split(","),
          condition: "in",
        },
      ];
    });

    const getOrderRequestList = () => {
      loading.value = true;
      ApiOrderRequest.getOrderRequesV2tList({
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        filter_group: [...getCommonFilterOptions.value, ...filterOptions.value],
        sort_orders: sortOptions.value,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getOrderRequestList();
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getOrderRequestList();
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      currentPage.value = 1;
      getOrderRequestList();
    };

    const typeChange = (e) => {
      searchItems();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "request_date_start") {
            filterArr.push({
              field: "request_date",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "request_date_end") {
            filterArr.push({
              field: "request_date",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "channel" || item == "order_progress") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "merchant") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      const values = getMerchantOrderProgress(route);
      if (val.order_progress.length === 0 && values.length > 0) {
        filterArr.push({
          field: "order_progress",
          value: values,
          condition: "in",
        });
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getOrderRequestList();
    };

    const handleFilterReset = () => {
      currentPage.value = 1;
      resetFilter();
      getOrderRequestList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getOrderRequestList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const fileImport = (type) => {
      let url = "";
      switch (type) {
        case 1:
          url = ApiOrderRequest.importConfirmedTrack();
          break;
        case 2:
          url = ApiOrderRequest.importIncomingShipments();
          break;
      }
      options.value.uploadUrl = url;
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      ApiOrderRequest.exportOrderRequest({
        merchant_request_id: id,
      })
        .then((data) => {
          item.downloadDisabled = false;
          commonExportFile(data);
          updateList();
        })
        .catch((error) => {
          item.downloadDisabled = false;
          console.log(error);
        });
    };

    const fileExportConfirmedTrack = () => {
      if (disabledExportConfirmedTrack.value) return;
      disabledExportConfirmedTrack.value = true;
      ApiOrderRequest.exportConfirmedTrack({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportConfirmedTrack.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportConfirmedTrack.value = false;
          console.log(error);
        });
    };

    const fileExportIncomingShipments = () => {
      if (disabledExportIncomingShipments.value) return;
      disabledExportIncomingShipments.value = true;
      ApiOrderRequest.exportIncomingShipments({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportIncomingShipments.value = false;
          commonExportFile(data);
        })
        .catch((error) => {
          disabledExportIncomingShipments.value = false;
          console.log(error);
        });
    };

    const checkedIsRequestDateFilter = computed(() => {
      let flagStart = true,
        flagEnd = true;
      flagStart = filterOptions.value.find(
        (item) => item.field === "request_date" && item.condition === "gteq"
      )
        ? false
        : true;
      flagEnd = filterOptions.value.find(
        (item) => item.field === "request_date" && item.condition === "lteq"
      )
        ? false
        : true;
      return !flagStart && !flagEnd ? false : true;
    });

    return {
      t,
      store,
      getDiffDays,
      getLateDays,
      formatDate,
      formatDateTime,
      CommonArrayToString,
      getOrderRequestProgressClass,
      getMerchantOrderRouterPath,
      isMB,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      getOrderRequestList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      typeChange,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      fileImport,
      fileExport,
      checkedIsRequestDateFilter,
      disabledExportConfirmedTrack,
      disabledExportIncomingShipments,
      fileExportConfirmedTrack,
      fileExportIncomingShipments,
    };
  },
});
